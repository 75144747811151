.builder {
    display: flex;
    flex-direction: row;

    .sideBar {
        flex: 0 0 1;
        width: 300px;
        padding: 10px;
        border-right: 1px solid #111;
        height: 100vh;
        background: #e1e1e1;
    }

    .sideBarItems {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: flex-start;
    }

    .sideBarItem {
        border: 1px solid #000;
        border-radius: 3px;
        width: 130px;
        height: 60px;
        padding: 10px;
        margin: 4px;
        background: #ccc;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;

        div {
            text-align: center;
        }
    }

    .page {
        flex: 1 1 auto;
        padding: 0 20px;
        margin: 20px;
        /*border: 1px solid $gray;*/
    }

    .dropZone {
        flex: 0 0 auto;
        height: 40px;
        transition: 200ms all;
    }

    .dropZone:nth-of-type(2n) {
        display: none;
    }

    .dropZone.horizontalDrag {
        width: 40px;
        height: auto;
    }

    .dropZone:not(.horizontalDrag).isLast {
        flex: 1 1 auto;
    }

    .dropZone.active,
    .trashDropZone.active {
        background: #00a2ff;
        transition: 100ms all;
    }


    .component {
        max-height: 200px;
        padding: 10px;
        background: #aaa;
    }

    .column {
        border: 1px dashed blue;
        background-color: $off-white;
        flex: 1 1 100%;
        padding: 10px;
    }

    .columns {
        display: flex;
        padding: 20px 0;
    }

    .columnContainer {
        display: flex;
    }

    .base {
        padding: 0.5rem 1rem;
        background-color: white;
        cursor: move;
    }

    .row {
        border: 1px solid $gray;
        padding: 5px;
    }

    .trashDropZone {
        position: fixed;
        right: 10px;
        bottom: 20px;
        text-align: center;
        padding: 20px 0;
        margin: 0 auto;
        width: 100px;
        height: 100px;
        border: 1px solid $gray;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $light-gray;

        .anticon {
            font-size: 2rem;
        }
    }

    .pageContainer {
        display: flex;
        flex: 1;
        flex-direction: column;
        margin-bottom: 100px;
    }
}