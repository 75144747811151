@import url(//fonts.googleapis.com/css?family=PT+Sans+Narrow:400,700|Inter:400,600,700,900);


$navbar-height: 64px;
$body-padding: $navbar-height;
$body-padding-simple: 64px;


// fonts
$font-stack-1: 'PT Sans Narrow', sans-serif;
$font-stack-2: 'Inter', sans-serif;

$small-width: 576px;
$medium-width: 768px;
$large-width: 992px;
$xlarge-width: 1200px;