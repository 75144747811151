/* card inside a card */
.ant-card .card-colored {
    background-color: $off-white;    
    box-shadow: 1px 1px 8px 2px rgba(17, 17, 26,0.5);

    .ant-card-head {
        background-color: $primary-alt;
        color: $off-white;
    }
}

.card-hover > img:hover {
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;  
}

.card-nopad .ant-card-body {
    padding: 0;
}

.card-selected {    
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;  
    border: 2px solid $primary-alt;
    background-color: $light-gray;
}