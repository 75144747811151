.menu-bar {
  text-align: center;
  margin: 20px auto;
  a {
    &:not(:last-of-type) {
      margin-right: 10px;
    }
  }
}


.side-nav {
  position: fixed;
  overflow-y: auto;
  overflow-x: hidden;
  top: 0;
  bottom: 0;    
  margin-top: 64px;
  
}

  .side-nav::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  .side-nav::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #777777; 
    border-radius: 10px;
  }
   
  /* Handle */
  .side-nav::-webkit-scrollbar-thumb {
    background: #777777; 
    border-radius: 10px;
  }
  
  /* Handle on hover */
  .side-nav::-webkit-scrollbar-thumb:hover {
    background: #555555; 
  }

.side-nav + section {
  position: relative;
  margin-left: 200px;
}

.side-nav.ant-layout-sider-collapsed + section {
  margin-left: 0;
}