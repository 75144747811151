.newsletter-table {
  margin-top:10px; margin-bottom:10px; border-bottom:dotted 1px #cccccc;
  width:500px;
}

.newsletter-thumb {
  padding:0 0 15px 0; width:100px;
}

.newsletter-body {
  font-size:14px; color:#595959; font-family:Arial, Helvetica, sans-serif; padding-left: 15px; padding-right:20px;
}

.newsletter-link {
  color:#336699; text-decoration:none; font-size:16px;
}

.newsletter-read-more {
  text-decoration:none; color:#336699;
}
