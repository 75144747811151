.content-editor {

    .ant-form {
        .ant-form-item {
            margin-bottom: 30px;

            .ant-form-item-label {
                padding: 0 0 2px;

                label {                
                    font-family: $font-stack-2;
                    color: $text-dark;
                    font-weight: bold;
                }
            }

            .ant-form-item {
                margin-bottom: 0;
            }
        }
    }


  .ant-row.editor-field {
    margin-bottom: 1.5rem !important;

    .ant-col:first-child {
      text-align: right;
      padding-right: 0.5rem !important;
      padding-top: 0.25rem !important;
    }
  }

  .required {
    color: $required;
    font-weight: bold;
  }

  .editor-sub-title {
    display: inline-block;
    font-weight: bold;
    font-family: $font-stack-2;
  }

  h1, h2, h3 ,h4 ,h5 {
    font-weight: 700;
  }

  .bordered {
      border: 1px solid $light-gray;
  }
} 