.wrapper {
    padding-top: $body-padding;
    @media (max-width: 767px) {
        padding-top: $navbar-height + 10px;
    }
}

.wrapper-simple {
    padding-top: $body-padding-simple;
    @media (max-width: 767px) {
        padding-top: $body-padding-simple + 10px;
    }
}

.ant-menu.ant-menu-horizontal {
    background-color: transparent;
    border-bottom: 0px;
}

.ant-menu-horizontal > .ant-menu-item:hover {
    color: $off-white;
    border-bottom: 0px;
}

.ant-menu-horizontal > .ant-menu-item a,
.ant-menu-horizontal > .ant-menu-item a:hover,
.ant-menu-horizontal .ant-menu-title-content {
    color: $off-white;
    font-weight: bold;
}

.navbar {
    align-items: center;
    background: #f5f5f5;
    border-bottom: 1px solid #e6d8d8;
    margin-bottom: 10px;

    background: $primary-main;
    color: white;

    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;

    .navbar-logo-container {
        img {
            max-width: 100%;
        }
        width: 60px;
        color: $text-dark;
    }

    .navbar-logo {
        height: 60px;
        vertical-align: top;
    }

    .navbar-menu {
        flex: auto;
    }

    .account-container {
        display: flex;
        align-items: center;
        cursor: pointer;

        .user-icon {
            margin-right: 10px;
            font-size: 25px;
        }
        .down-icon {
            margin-left: 10px;
        }
    }

    @media (max-width: 590px) {
        padding: 0 15px;

        .navbar-logo {
            height: 31px;
        }

        .account-container {
            .user-icon {
                margin-right: 0;
                font-size: 20px;
            }
            .account-username {
                display: none;
            }
            .down-icon {
                margin-left: 0;
            }
        }
    }
}

//navbar responsive
.navbar {
    .anticon-menu {
        font-size: 1.3rem;
        color: white;
    }

    .anticon {
        font-size: 1.2rem;
        color: white;
    }

    .ant-divider-vertical {
        height: 90%;
        border-left-color: white;
    }

    .responsive-container {
        .mobile {
            display: none;
            .dropdown {
                margin-bottom: 20px !important;
                width: 100%;
            }
        }

        font-size: 14px;
        font-weight: 500;

        .link-row {
            margin-left: 40px;
            a {
                color: rgba(255, 255, 255, 0.5);
                margin-left: 20px;

                &.active {
                    color: white;
                }

                &:hover,
                &:active,
                &:focus {
                    color: white;
                    text-decoration: none;
                }
            }
        }

        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        width: 100%;

        @media (max-width: 767px) {
            width: auto;

            .mobile {
                display: flex;
                align-items: center;
            }
            .desktop {
                display: none;
            }
        }
    }
}
