.ant-drawer {
    color: white;

    .ant-drawer-header {
        max-height: 64px;
        background: $primary-main;
        padding: 0 20px;
        color: white;
    }

    .ant-drawer-title {
        padding: 20px;
        color: white;
        font-weight: bold;
    }

    .navbar-logo-container {
        img {
            max-width: 100%;
        }
        width: 60px;
        color: $text-dark;
    }

    .navbar-logo {
        height: 60px;
        vertical-align: top;
    }
}
