
.ant-upload.ant-upload-select-picture-card {

  width: 200px;
  height: 200px;
  max-width: 100%;
  max-height: 100%;

  img {
    max-height: 100%;
  }
}
